import React from "react";


const Header = ({ siteTitle, clicker, active }) => {

  return (
    <header>
      <div className="nav">
        <button onClick={() => clicker(true)} className={active ? "hamburger hamburger--squeeze is-active" : "hamburger hamburger--squeeze"} type="button"
          aria-label="Menu" aria-controls="navigation">
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </button>
      </div>

    </header>
  )
}
export default Header
