import React from 'react'
import { Link } from "gatsby"
const Proposal = ({ linkTo, linkBack, titleTo, titleBack, fwd, bck, }) => {
    return (
        <div className="proposal">
            <div className="container">
                <div className="link-block">
                    <Link to={"/" + linkBack}> <div className="back">
                        {bck ? <>
                            <p>Go Back:</p>
                            <h2>{titleBack}</h2>
                            <img src="/images/arrow_left.svg" width="60px" />
                        </> : <></>}
                    </div></Link>
                    <Link to={"/" + linkTo}>
                        <div className="to">
                            {fwd ? <><p> Up Next:</p>
                                <h2>{titleTo}</h2>
                                <img src="/images/arrow.svg" width="60px" /></> : <></>}

                        </div>
                    </Link>
                </div>


            </div>
        </div>
    )
}
export default Proposal;