import React from 'react'
import { Link } from "gatsby"
const Sidebar = () => {
    return (

        <div className="sidebar">
            <Link to="/">Introduction</Link>
            <Link to="/about-us">About Us</Link>
            <Link to="/purpose">Place Purpose</Link>
            <Link to="/vision">Downtown West</Link>
            <Link to="/article">Article</Link>
            <Link to="/studies">Case Studies</Link>
            <Link to="/summary">Summary</Link>
        </div>

    )
}

export default Sidebar
