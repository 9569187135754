
import React, { useState } from "react"
import Header from "./header"
import Footer from "./templates/footer.js"
import Sidebar from './templates/sidebar';
import Proposal from '../components/templates/proposal';
import 'bootstrap/dist/css/bootstrap.min.css';
import "hamburgers/dist/hamburgers.min.css";
import "./styles/layout.scss"


const Layout = ({ children }) => {
  const [active, setActive] = useState(false);
  const handleClick = (check) => {
    setActive(prev => prev != check);
  }
  return (
    <>
      <Header clicker={handleClick} active={active} />
      {active ? <>
        <Sidebar isClosed={active} />
        <main>{children}</main>
        <Footer />
      </> :
        <>
          <main>{children}</main>
          <Footer />
        </>
      }


    </>
  )
}

export default Layout
